import React from 'react';

import { ContainerOneColumn, H1, H2 } from '_templates/Sections';

/**
 * The "Terms of Service" section.
 *
 * @returns {React.Component} Section.
 */
const TermsOfService = () => (
  <ContainerOneColumn>
    <H1>Terms of Service</H1>
    <p>
      These terms and conditions (&#8220;Terms&#8221;, &#8220;Agreement&#8221;) are an agreement
      between Pricematepay (&#8220;Pricematepay&#8221;, &#8220;us&#8221;, &#8220;we&#8221; or
      &#8220;our&#8221;) and you (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;). This
      Agreement sets forth the general terms and conditions of your use of the Pricematepay.com
      website and any of its products or services (collectively, &#8220;Website&#8221; or
      &#8220;Services&#8221;).
    </p>
    <H2 textAlign="left">Billing and payments</H2>
    <p>
      You shall pay all fees or charges to your account in accordance with the fees, charges, and
      billing terms in effect at the time a fee or charge is due and payable. If, in our judgment,
      your purchase constitutes a high-risk transaction, we will require you to provide us with a
      copy of your valid government-issued photo identification, and possibly a copy of a recent
      bank statement for the credit or debit card used for the purchase.
    </p>
    <H2 textAlign="left">Accuracy of information</H2>
    <p>
      Occasionally there may be information on the Website that contains typographical errors,
      inaccuracies or omissions that may relate to product descriptions, pricing, promotions and
      offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change
      or update information or cancel orders if any information on the Website or on any related
      Service is inaccurate at any time without prior notice (including after you have submitted
      your order). We undertake no obligation to update, amend or clarify information on the Website
      including, without limitation, pricing information, except as required by law. No specified
      update or refresh date applied on the Website should be taken to indicate that all information
      on the Website or on any related Service has been modified or updated.
    </p>
    <H2 textAlign="left">Third-party services</H2>
    <p>
      If you decide to enable, access or use third-party services, be advised that your access and
      use of such other services are governed solely by the terms and conditions of such other
      services, and we do not endorse, are not responsible or liable for, and make no
      representations as to any aspect of such other services, including, without limitation, their
      content or the manner in which they handle data (including your data) or any interaction
      between you and the provider of such other services. You irrevocably waive any claim against
      Pricematepay with respect to such other services. Pricematepay is not liable for any damage or
      loss caused or alleged to be caused by or in connection with your enablement, access or use of
      any such other services, or your reliance on the privacy practices, data security processes or
      other policies of such other services. You may be required to register for or log into such
      other services on their respective websites. By enabling any other services, you are expressly
      permitting Pricematepay to disclose your data as necessary to facilitate the use or enablement
      of such other service.
    </p>
    <H2 textAlign="left">Backups</H2>
    <p>
      We are not responsible for Content residing on the Website. In no event shall we be held
      liable for any loss of any Content. It is your sole responsibility to maintain appropriate
      backup of your Content. Notwithstanding the foregoing, on some occasions and in certain
      circumstances, with absolutely no obligation, we may be able to restore some or all of your
      data that has been deleted as of a certain date and time when we may have backed up data for
      our own purposes. We make no guarantee that the data you need will be available.
    </p>
    <H2 textAlign="left">Links to other websites</H2>
    <p>
      Although this Website may link to other websites, we are not, directly or indirectly, implying
      any approval, association, sponsorship, endorsement, or affiliation with any linked website,
      unless specifically stated herein. We are not responsible for examining or evaluating, and we
      do not warrant the offerings of, any businesses or individuals or the content of their
      websites. We do not assume any responsibility or liability for the actions, products,
      services, and content of any other third-parties. You should carefully review the legal
      statements and other conditions of use of any website which you access through a link from
      this Website. Your linking to any other off-site websites is at your own risk.
    </p>
    <H2 textAlign="left">Limitation of liability</H2>
    <p>
      To the fullest extent permitted by applicable law, in no event will Pricematepay, its
      affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any
      person for (a): any indirect, incidental, special, punitive, cover or consequential damages
      (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or
      content, impact on business, business interruption, loss of anticipated savings, loss of
      business opportunity) however caused, under any theory of liability, including, without
      limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even
      if Pricematepay has been advised as to the possibility of such damages or could have foreseen
      such damages. To the maximum extent permitted by applicable law, the aggregate liability of
      Pricematepay and its affiliates, officers, employees, agents, suppliers and licensors,
      relating to the services will be limited to an amount greater of one dollar or any amounts
      actually paid in cash by you to Pricematepay for the prior one month period prior to the first
      event or occurrence giving rise to such liability. The limitations and exclusions also apply
      if this remedy does not fully compensate you for any losses or fails of its essential purpose.
    </p>
    <H2 textAlign="left">Changes and amendments</H2>
    <p>
      We reserve the right to modify this Agreement or its policies relating to the Website or
      Services at any time, effective upon posting of an updated version of this Agreement on the
      Website. When we do, we will post a notification on the main page of our Website. Continued
      use of the Website after any such changes shall constitute your consent to such changes.
    </p>
    <H2 textAlign="left">Acceptance of these terms</H2>
    <p>
      You acknowledge that you have read this Agreement and agree to all its terms and conditions.
      By using the Website or its Services you agree to be bound by this Agreement. If you do not
      agree to abide by the terms of this Agreement, you are not authorized to use or access the
      Website and its Services.
    </p>
    <H2 textAlign="left">Contacting us</H2>
    <p>If you have any questions about this Agreement, please contact us.</p>
    <p>This document was last updated on March 10, 2021.</p>
  </ContainerOneColumn>
);

export default TermsOfService;
