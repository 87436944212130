import styled from 'styled-components';

import colours from '_theming/colours';
import breakpoints from '_util/responsiveDesign/breakpoints';

// Extract responsive design variables.
const { mobile, tablet } = breakpoints;

/**
 * Container for a section which only has one column.
 */
const ContainerOneColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;

  padding: 0 25px;

  color: ${colours.daintree};
`;

/**
 * Component container which has two columns.
 */
const ContainerTwoColumn = styled.section`
  display: flex;

  @media screen and (max-device-width: ${mobile.max}px) {
    flex-flow: column nowrap;

    & > * {
      max-width: 100%;
    }

    & > *:first-child {
      text-align: center;
    }
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: ${(props) => props.flexDirection || 'row'};

    & > * {
      flex-basis: 49%;
    }
  }

  padding: 0 25px;

  color: ${colours.daintree};
`;

/**
 * Container for the graphic.
 */
const ContainerGraphic = styled.div`
  svg {
    width: 100%;
    height: auto;
  }
`;

/**
 * Container for the buttons.
 */
const ContainerButtons = styled.div`
  @media screen and (max-device-width: ${mobile.max}px) {
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
    align-items: center;

    & > a {
      width: 100%;
    }

    & > a:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    & > a:not(:last-child) {
      margin-right: 32px;
    }

    margin-top: 32px;
    width: 100%;
  }
`;

/**
 * Section H1 header.
 */
const H1 = styled.h1`
  @media screen and (max-device-width: ${mobile.max}px) {
    font-size: 1.5rem;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    font-size: 2.5rem;
  }

  font-family: 'Big Caslon Medium', serif;
  text-align: ${(props) => props.textAlign || 'left'};

  &:first-child {
    margin-top: 0;
  }
`;

/**
 * Section H2 header.
 */
const H2 = styled.h2`
  @media screen and (max-device-width: ${mobile.max}px) {
    font-size: 1.5rem;
  }

  @media screen and (min-device-width: ${tablet.min}px) {
    font-size: 2rem;
  }

  font-family: 'Big Caslon Medium', serif;
  text-align: ${(props) => props.textAlign || 'center'};

  &:first-child {
    margin-top: 0;
  }
`;

export { ContainerOneColumn, ContainerTwoColumn, ContainerGraphic, ContainerButtons, H1, H2 };
